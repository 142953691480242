@import 'variables';

.rs-pi-icon-sized {
  margin: 10px;
  font-size: 20px;
  color: rgb(var(--primary-color));
  &:hover {
    color: rgba(var(--primary-color), 1.1) !important;
  }
}

.rs-pi-btn-hovered {
  &:hover {
    color: rgba(var(--primary-color), 1.1) !important;
  }
  &:active {
    color: rgba(var(--primary-color), 1.2) !important;
  }
}

.rs-pi-no-label > .p-button-label {
  display: none;
}

.rs-pi-no-icon > .p-button-icon {
  display: none;
}

.rs-pi-btn {
  padding: 12px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}

.rs-btn-small {
  padding: 8px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  border: 1px solid #fc3b00;
  height: 25px;
}

.p-progressbar {
  height: 5px !important;
}

// .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
//   border: 1px solid transparent;
// }

p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .tabs-style {
  font-weight: bold;
  padding: 0 10px;
  &:hover {
    color: rgb(var(--primary-color));
  }
}

// fix for toasts messages overflowed by thead of tables
.p-datatable-scrollable .p-datatable-thead {
  z-index: unset !important;
}

// ::ng-deep .p-tooltip {
//   max-width: fit-content;
//   width: 600px !important;
//   > .ui-tooltip-text {
//     width: 600px !important;
//   }
// }
.appFixTooltipWidth > .p-tooltip-text {
  min-width: 300px !important;
  width: fit-content;
}
