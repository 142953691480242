// material icons styles overrides

// webfont loading Material icons
// don't show mat-icon until font is loaded, unless using a different font set
body:not(.material-icons-loaded) .mat-icon {
  display: none;
}
body.material-icons-error .mat-icon {
  display: initial;
}

.material-icons-outlined {
  font-family: 'Material icons';
  color: black;
  font-weight: 500;
  font-variation-settings: 'FILL' 0, 'wght' 500, 'GRAD' 0, 'opsz' 48;
}
