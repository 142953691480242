
$primaryDarkOrange: #fb2424;
$darkGray: #414042;
$mediumGray: #a5a1a1;
$lightGray: #efefef;
$textWhiteColor: #ffffff;
$primaryYellow: #ffab0c;

// gbp
$systemGreen: #33b100;
$systemLightGreen: #c4e9bf;
$primaryBlue: #0270df;
$ligntBlue: #007eff;

$textColor: $darkGray;
$textSecondaryColor: $mediumGray;
$errorColor: $primaryDarkOrange;

$trs: 0.5s ease;

// New colour codes.
$black: #000000;
