@import 'core/mixins';

.form-column {
  display: flex;
  flex-direction: column;
  width: 49%;
}

.form-row {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .input-field {
    display: block;
    position: relative;
    width: 49%;
    margin: 10px 0;

    .input-label {
      position: absolute;
      top: -9px;
      left: 8px;
      padding: 2px;
      background: white;
      text-transform: uppercase;
      font-size: 13px;
    }

    input {
      width: 100%;
      transition: $trs;
      border-radius: 4px;
      border: solid 2px #eee;
      background: white;
      &:hover,
      &:focus {
        border: solid 2px rgba(51, 177, 0, 0.5);
      }
    }

    &.radio {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      p-radioButton {
        margin-right: 10px;
      }
    }
  }

  &.half .input-field {
    width: 49%;
  }
  &.fullWidth .input-field {
    width: 100%;
  }
}
