@import 'variables';

.flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .p-button-text {
    color: rgb(var(--primary-color));
    padding: 8px 8px 8px 0px;

    .p-button-label {
      text-decoration: underline;
    }
  }

  .p-button.p-button-text:enabled:hover {
    background: transparent;
  }
}

div.center-location {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .location-data {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;

    // max-width: 1920px;
    height: 100%;
    padding: 20px;
    // margin-right: 15px;
    width: 100%;

    .card {
      width: 100%;
      margin: 0px;
      margin-bottom: 20px;
    }

    header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      height: 3em;

      button.edit {
        display: flex;
        align-items: center;
        height: 2.5em;
        background: transparent;
        border: transparent;
        color: black;

        span {
          display: flex;

          padding: 0;
        }
      }
    }

    .first-column {
      display: flex;
      flex-direction: column;
      width: 32%;
    }
    .second-column {
      display: flex;
      flex-direction: column;
      width: 32%;
    }
    .third-column {
      display: flex;
      flex-direction: column;
      width: 32%;
    }

    a {
      color: rgb(var(--primary-color));
    }
  }
}
// }

// .page-container-loc-dashboard {
//   width: 100%;

//   .flex {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: flex-start;
//   }

//   .p-button-text {
//     color: rgb(var(--primary-color));
//     padding: 8px 8px 8px 0px;
//   }

//   .table-container {
//     width: 100%;
//     padding-right: 15px;
//   }
// }

// page-flex {
//   display: flex;
//   flex-direction: column;
//   justify-self: flex-start;
//   align-content: flex-start;
// }

// .tabs-style {
//   display: flex;
//   justify-content: center;
//   width: 170px;
//   border: none;
//   font-weight: lighter;
// }

// .tabs-style:hover {
//   font-weight: bolder;
//   border: none;
// }

// .tabs-style:focus {
//   outline: none;
// }
// .location-data {
//   .flex-div {
//     display: flex;
//     margin-top: 50px;
//   }
// }
// app-loc-dashboard {
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-items: center;

//   flex-wrap: wrap;

//   width: 100%;
//   height: 91.5vh;
//   overflow: auto;

//   div.col-group {
//     display: inline-block;
//     column-count: 2;
//     column-fill: balance;
//     width: 90em;
//   }

//   p-tabMenu.tabs-menu {
//     margin-bottom: 20px;
//   }
// }

// header.main {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   justify-self: center;

//   width: 1230px;

//   h2 {
//     width: 1030px;
//     text-align: center;
//   }
// }

// .divider {
//   border-bottom: 1px solid darkgray;
//   margin: 1em 0 1em 0;
//   width: 96%;
// }

// .group-horizontally {
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: space-between;

//   width: 90em;
//   padding-bottom: 1em;
// }

// .card {
//   margin: 0 1em 1em 1em;
//   break-inside: avoid;

//   .card-content {
//     display: flex;
//     flex-direction: column;

//     width: 100%;
//     padding: 1em 1em 0.5em 1em;

//     header {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;

//       width: 100%;
//       height: 3em;

//       button.edit {
//         display: flex;
//         align-items: center;

//         height: 2.5em;
//         width: 5em;
//         background: transparent;
//         border: transparent;
//         color: black;

//         span {
//           display: flex;

//           padding: 0;
//         }
//       }
//     }

//     article {
//       display: flex;
//     }

//     h2,
//     h3,
//     h4 {
//       margin: 0;
//       padding: 0 0 0 0.8em;
//     }

//     p {
//       margin: 0;
//       padding: 0 0 0 1em;
//     }
//   }

//   footer {
//     display: flex;
//     align-items: flex-end;
//     justify-content: flex-end;

//     padding: 0 1em 0 1em;
//   }
// }

// div.placeholder {
//   border: 1 px solid darkgray;
//   background: lightgray;
//   font-style: italic;
//   text-align: center;
//   padding-top: 2em;
//   margin-right: 1em;
// }

// /* Tablet view */
// @media only screen and (max-width: 920px) {

//   app-loc-contact-widget {
//     .card.location-contact {
//       width: 600px !important;

//       aside {
//         width: 95% !important;
//         padding-right: 1.5em;
//       }

//       div.content {
//         flex-direction: column;

//       }
//       div.placeholder {
//         visibility: hidden;
//       }
//     }
//   }

//   app-loc-identity-widget {
//     .card {
//       width: 600px !important;
//     }
//   }

// }

/* Narrow view */
@media only screen and (max-width: 1347px) {
  header.main {
    justify-content: space-between;

    width: 600px !important;

    h2 {
      width: 490px !important;
      text-align: right;
      padding-right: 0.5em;
    }
  }

  .group-horizontally {
    flex-direction: column;
    align-items: center;
  }

  div.col-group {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  app-loc-contact-widget {
    .card.location-contact {
      width: 600px !important;

      header {
        h3 {
          max-width: 450px !important;
        }
      }

      article {
        flex-direction: column;

        aside {
          width: 97% !important;
        }

        div.placeholder {
          align-self: center;

          width: 97% !important;
          margin-top: 2em;
        }
      }

      p-dialog {
        article.popup {
          flex-direction: row;
        }
      }
    }
  }

  // app-loc-identity-widget {
  //   .card {
  //     width: 600px !important;
  //   }
  // }

  // app-loc-status-widget {
  //   .card {
  //     width: 600px !important;
  //     margin-bottom: 0;
  //   }
  // }
}

//activity log
div.activity-log {
  margin-right: 15px;

  p-table.activity-log-table {
    .flex-search-bar {
      display: flex;
      align-items: center;
      width: 100%;
      .acct-search {
        width: 100%;
        .p-input-icon-right,
        .p-input-icon-left,
        input {
          width: 100%;
        }
        .p-input-icon-right i {
          z-index: 1;
          padding-top: 2px;
        }
      }
    }

    tr.details,
    th.details,
    td.details {
      display: flex;
      justify-content: flex-start;
    }
    tr.user,
    th.user,
    td.user {
      display: flex;
      justify-content: center;
      max-width: 250px;
    }

    tr.date-time,
    th.date-time,
    td.date-time {
      display: flex;
      justify-content: center;
      max-width: 250px;
    }

    p-dialog .footer {
      text-align: right;
      margin: 10px;
    }
  }
}
