::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(#eee, 0.95);
}

::-webkit-scrollbar-thumb {
  background: rgba($color: var(--primary-color), $alpha: 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($color: var(--primary-color), $alpha: 0.6);
}
