@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex {
  display: flex;
  align-items: center;
}

@mixin flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

@mixin btn-reset {
  background: transparent;
  border: none;
  cursor: pointer;
}

@mixin table-filter {
  width: 100%;
  height: 100%;
  background-color: $lightGray;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 10%);
  border-radius: 8px;
}
