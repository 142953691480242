.p-button {
  transition: none !important;
  .p-button-icon {
    transition: none !important;
  }
  .p-button-label {
    white-space: nowrap;
    transition: none !important;
  }
}
