// .p-dialog-content:not(:nth-last-of-type(.p-dialog-footer)) {
//   border-radius: 0 0 10px 10px;
// }

p-dialog .dialog-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;

  width: 500px;

  .header-div {
    width: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    div {
      font-size: 16px;
      text-align: center;
    }

    .new-acct-logo {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 10em;
      height: 10em;
      border-radius: 5em;
      background: #dcdbdb;
      border: 2px solid #707070;
    }

    .create-acct-header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: fit-content;

      .create-header-text {
        font-size: 2em;
      }
      .create-header-dot {
        color: rgb(var(--primary-color));
        font-size: 2em;
      }

      h2 {
        margin: 1em 0 0.5em 0;
      }
    }
  }

  input {
    margin: 0.5em 0;
    width: 100%;
  }

  .flex-circle-message {
    width: 97%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .circle {
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: darkgrey;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: red;
      color: white;

      .image-error-message {
        width: 97%;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-left: 10px;
        color: red;
      }
    }
  }

  .logo-upload {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    input {
      width: 100%;
    }

    p-chips {
      width: 100%;
    }

    p-fileupload {
      position: absolute;
      right: 2.5em;

      .p-button-icon-only {
        background: none;
        border: none;
        width: 1.5em;
        height: 2em;
      }

      .pi.pi-upload {
        color: rgb(var(--primary-color));
      }

      .p-button:focus {
        box-shadow: none !important;
      }
    }
  }

  .new-acct-radio-buttons {
    display: flex;
    justify-content: space-between;

    width: 100%;
    margin: 0.5em 0 1.5em 0;

    .input-radio {
      display: flex;
      align-items: center;

      background-color: #efefef;
      width: 49%;
      height: 40px;
      border-radius: 8px;

      .subacct-radiobuttons {
        display: flex;
        justify-content: center;

        width: 20%;
      }

      .radio-text {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        width: 80%;
        height: 40px;
        padding-left: 10%;
        color: #a5a1a1;
        border-left: 1px solid #a5a1a1;
      }
    }
  }

  .next-button {
    display: flex;
    justify-content: center;

    width: 100%;
    margin-bottom: 2.5em;

    .p-button-primary {
      width: 100%;
      height: 40px;
    }
  }

  .display2-button-flex {
    width: 100%;
    display: flex;
    justify-content: center;

    .p-button-outlined,
    .p-button-primary {
      width: 40%;
      height: 37px;
    }
  }

  .grey-area {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 200px;
    margin-top: 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    background: lightgrey;
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 110px;
    // margin-top: 180px;
    border-radius: 100px;
    background-color: white;
    border: 5px solid darkgrey;
  }
}

.p-dialog-footer {
  p-footer {
    display: flex;
    justify-content: space-between;
  }
}
