@mixin flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@mixin input-styles {
  width: 100%;
  height: 36px;
  padding-left: 15px;
  margin: 0px 0px 11.2px;
}

.input-label-with-tooltip {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  .question-mark {
    margin-left: 10px;
  }
}

.inputs-flex-country {
  @include flex-column;
  &.row {
    flex-direction: row;
  }

  p-autocomplete {
    .p-autocomplete {
      width: 100%;
    }

    button.p-autocomplete-dropdown.p-button-icon-only {
      height: 36px;
    }
  }

  input {
    @include input-styles;
  }
}

.inputs-flex-bottom-top {
  @include flex-column;
  &.row {
    flex-direction: row;
  }

  input {
    width: 100%;
    height: 36px;
    margin: 0px 0px 11.2px;
  }

  p-chips {
    .p-chips {
      width: 100%;

      .p-chips-multiple-container {
        width: 100%;
        padding: 10px 10px 10px 15px;
        list-style-type: none;
        cursor: text;
        overflow: hidden;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 0px 0px 11.2px;
        min-height: 46px !important;
      }

      .p-chips-input-token {
        margin: 0px;
      }

      .p-chips-multiple-container .p-chips-token {
        padding: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        background-color: $systemGreen;
        color: white;
        font-weight: normal;
      }
    }
  }
}

.inputs-flex-bottom-bottom {
  @include flex-column;
  &.row {
    flex-direction: row;
  }

  textarea {
    height: 150px;
    padding-left: 15px;
    margin-bottom: 10px;
  }
}

.inputs-flex {
  @include flex-column;
  position: relative;
  &.row {
    flex-direction: row;
  }

  ::ng-deep p-dropdown {
    width: 100%;
    .p-dropdown.p-component {
      width: 100%;
    }
  }
  input {
    @include input-styles;

    &.ng-invalid:not(.ng-pristine) {
      border: 1px solid $primaryDarkOrange;
    }
  }

  .p-chips-multiple-container {
    display: flex;
    align-content: center;
    justify-content: flex-start;

    width: 300px;
    height: 36px;
    margin: 0px 0px 11.2px;
    padding: 0px;

    .p-chips-input-token input {
      font-size: 15px;
    }
  }

  .for-new-loc {
    .input-w-addon {
      width: 100%;

      input {
        @include input-styles;
      }
    }
  }

  p-dropdown {
    .p-dropdown.p-component {
      width: 100%;
      height: 36px;
    }
    .p-dropdown-label {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  p-calendar {
    width: 100%;
    .p-calendar.p-calendar-w-btn {
      width: 100%;
    }
    input {
      @include input-styles;
    }
    .p-button.p-button-icon-only {
      height: 36px;
      background-color: #efefef;
      border: 1px solid #efefef;
      color: #a9a5a5;
    }
  }
}
