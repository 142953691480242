@import 'variables';

// TODO: refactor to simpler title overall the project
.header-parent-title {
  display: flex;
  height: 80px;
  align-items: center;
  padding-left: 0.3em;
}

.header-child-title {
  display: flex;
  font-size: 40px;
  font-weight: bolder;
  align-items: center;
}

.header-child-title:last-child {
  color: rgb(var(--primary-color));
}
