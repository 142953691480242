/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
  // box-shadow: none !important;
}

body {
  margin: 0 auto !important;
  float: none !important;
  margin: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #414042;
  overflow-x: hidden;
  // overflow: hidden; // TODO: cause disabling scroll on most of pages
}
.p-component {
  font-size: 14px;
}

// temporarily added to block the background in popups until we update PrimeNG to newest version
div.p-dialog-mask {
  pointer-events: auto;
}

//user menu (right of breadcrumb) overlay
.p-menu.p-menu-overlay {
  ul {
    width: 100%;

    li {
      width: 100%;
      height: 49px;

      a {
        width: 100%;
        height: 100%;

        .p-menuitem-text {
          margin: 0;
          font-size: 19px;
          font-weight: normal;
        }
      }
    }
  }

  .p-menuitem-link:not(.p-disabled):hover {
    background: $mediumGray;
  }
}

.p-chips .p-chips-multiple-container li.p-chips-token {
  padding: 1px 2px 1px 2px;
  margin-right: 4px;
}

span.p-chips-token-label,
span.p-autocomplete-token-label {
  font-size: 10pt;
  margin-right: 2px;
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 2px;
  margin-right: 2px;
}

.p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container li.p-autocomplete-token {
  padding: 3px 3px 2px 3px;
}
p-autocomplete button.p-autocomplete-dropdown.p-button-icon-only {
  margin: 0;
  padding: 4px 1px 2px 0px;
  width: 30px;
}
p-autocomplete button.p-autocomplete-dropdown.p-button-icon-only span.p-button-label {
  display: none;
}
.p-autocomplete.p-autocomplete-multiple
  .p-autocomplete-multiple-container
  .p-autocomplete-token
  .p-autocomplete-token-icon {
  margin-left: 1px;
}

.p-checkbox {
  margin-top: 3px;
}
.p-checkbox-label {
  margin: 5px 10px 2px 3px;
}

.p-dialog {
  max-width: calc(100vw - 20px);
}

body .p-widget label.p-chkbox-label {
  margin-left: 5px;
  margin-right: 10px;
}

.p-column-filter {
  width: 100%;
}

p-checkbox.for-fieldset {
  position: relative;
  top: -2px;
  left: 1px;
}
p-checkbox.for-fieldset .p-checkbox-label {
  margin-right: 2px;
}

// Carousel options
.p-carousel-container {
  button.p-carousel-prev,
  button.p-carousel-next {
    width: 1em !important;
    margin: 0 0.2em !important;
  }
}

ul.p-carousel-indicators li {
  border: 1px solid #a6a6a6;
}

button.p-link {
  box-shadow: none !important;
}

.cat-tooltip > .p-tooltip-text {
  width: 300px !important;
}

//arrow buttons in hour-inputs widget
button.increase .p-button-icon {
  font-size: 10px !important;
  font-weight: bold !important;
  margin-top: 5px;
}

button.decrease .p-button-icon {
  font-size: 10px !important;
  font-weight: bold !important;
  margin-bottom: 5px;
}

////Reviews page

.show-graphs .p-button-icon {
  font-size: 19px;
  color: $textColor;
}

.hide-graphs .p-button-icon {
  font-size: 17px;
}

button.export {
  .p-button-label {
    flex: 0 1 auto;
  }
}

p-checkbox.review-table,
p-tableCheckbox.review-table,
p-tableCheckbox.bigger-checkboxes {
  width: 20px;
  height: 20px;

  .p-checkbox {
    height: 100%;
    width: 100%;

    .p-checkbox-box {
      height: 100%;
      width: 100%;
    }
  }
}

button.edit-reply {
  .pi.pi-plus {
    font-size: 18px;
    font-weight: bold;
  }
}

.normal-width-tooltip > .p-tooltip-text {
  width: fit-content !important;
}

.show-tooltip > .p-tooltip-text {
  width: 155px !important;
}

.dashboard-tooltip > .p-tooltip-text {
  width: 190px !important;
}

.hide-tooltip > .p-tooltip-text {
  width: 135px !important;
}

.percent-tooltip > .p-tooltip-text {
  width: 210px !important;
}

.rating-tooltip > .p-tooltip-text {
  width: 195px !important;
}

.export-tooltip > .p-tooltip-text {
  width: 210px !important;
}

.review-tooltip > .p-tooltip-text {
  width: 400px !important;
  padding: 1.2rem 1.6rem;
}

.redirect-tooltip > .p-tooltip-text {
  width: 165px !important;
}

.reply-tooltip > .p-tooltip-text {
  width: 300px !important;
  padding: 1.2rem 1.6rem;
}

.green .p-rating .p-rating-icon.pi-star,
.green .p-rating .p-rating-icon.pi-star-o {
  color: rgb(var(--primary-color));
}

.yellow .p-rating .p-rating-icon.pi-star,
.yellow .p-rating .p-rating-icon.pi-star-o {
  color: $primaryYellow;
}

.red .p-rating .p-rating-icon.pi-star,
.red .p-rating .p-rating-icon.pi-star-o {
  color: $primaryDarkOrange;
}

// Pending Reviews Page
// .response-tooltip {
//   width: 420px;
//   background-color: #414042;
//   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
//   padding: 1.2rem 1.6rem;
//   color: #faf8f8;

//   &-review {
//     font-style: italic;
//   }

//   &-bold {
//     font-weight: bold;
//   }

//   &-button {
//     width: 100%;
//     padding: 0.5rem 7rem;
//   }
// }

// Google and Yelp Reviews page
.reviews-multi-response {
  .p-dialog {
    width: 70vw;
    height: 780px;
    align-items: center;

    .p-dialog-header {
      visibility: hidden;
    }

    .p-dialog-content {
      overflow: hidden;
      background: transparent;
      width: 100%;

      p.small {
        font-size: 12px;
      }

      p.bold {
        font-weight: bold;
      }

      .review-cards {
        width: 100%;
        height: 35%;
        margin-bottom: 70px;

        .first {
          top: 32px;
          left: 0px;
        }

        .second {
          top: -243px;
          left: 8px;
        }

        .third {
          top: -519px;
          left: 16px;
        }

        .fourth {
          top: -795px;
          left: 24px;
        }

        .fifth {
          top: -1071px;
          left: 32px;
        }

        .card {
          display: flex;
          justify-content: space-between;
          align-items: center;

          position: relative;
          width: 97%;
          height: 100%;
          border-radius: 6px;
          box-shadow: -2px 2px 5px 0 rgb(105 101 101 / 20%) !important;

          .button-container {
            display: flex;
            justify-content: center;

            width: 5%;

            button.switch-card {
              width: 30px;
              height: 30px;
              background: rgb(var(--light-background-color));
              box-shadow: 0px 1px 5px 0 rgb(0 0 0 / 20%) !important;
              background: transparent;
              border: none;

              .p-button-icon {
                color: rgb(var(--primary-color));
              }
            }
          }

          .middle-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            height: 100%;
            width: 90%;

            .review-number {
              margin-top: 10px;
            }

            .review-content {
              display: flex;
              justify-content: space-between;

              height: 73%;
              width: 100%;

              .directory-store {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                width: 15%;
                height: 100%;

                img {
                  width: 120px;
                  border-radius: 50%;
                }

                span {
                  margin-bottom: 4px;
                }

                p {
                  margin: 0;
                }

                p.bold {
                  font-size: 20px;
                  text-align: center;
                }
              }

              .review-details {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                width: 85%;
                height: 100%;

                i {
                  font-size: 19px;
                }

                .full-review {
                  width: 100%;
                }

                .review-footer {
                  width: 100%;

                  div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                      margin: 0;
                    }

                    .poster-details {
                      display: flex;

                      p-rating {
                        margin: 0 5px;

                        .p-rating .pi {
                          color: $primaryYellow;
                        }
                      }
                    }

                    .external-review {
                      display: flex;

                      button {
                        color: $textColor;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .reply {
        align-self: flex-end;
        display: flex;
        flex-direction: column;
        align-items: center;

        height: 55%;
        background-color: white;
        border-radius: 6px;
        margin-right: 9px;

        textarea {
          padding: 30px;
          font-size: 17px;
          font-weight: 100;
          width: 90%;
          height: 90%;
          border-radius: 4px;
          border: solid 1px rgba(0, 0, 0, 0.2);
          margin-top: 40px;
        }

        .counter {
          align-self: flex-end;
          position: relative;
          font-style: italic;
          font-weight: 300;
          left: -100px;
          top: -28px;
        }
      }

      .footer-content {
        display: flex;
        justify-content: flex-end;
        width: 96%;
        padding-right: 50px;
        padding-bottom: 30px;

        button {
          width: 200px;
          height: 50px;
          margin-left: 20px;
          padding: 0;

          .p-button-label {
            flex: 1 0 auto;
          }
        }
      }
    }
  }
}

.multiselect-paginator {
  background: none;
}

// Card component
.card {
  border: 1px solid #d7d6d6;
  border-radius: 10px;
  box-shadow: 0px 3px 17px #0000000d;
  background-color: white;
  // padding: 20px; // INFO due to using .card class only in locations pages
  header {
    // TODO: delete all styles inside widgets and refactor widgets to one wrapper
    padding: 25px !important;
  }

  hr {
    border-color: #e6e6e6;
    border-width: 0 0 1px 0;
    margin: 1px 0;
  }
}

// logo
img.logo {
  vertical-align: middle;
  height: 45px;
  margin: 3px 0 0 0;
}

.p-toast-top-right {
  top: 70px !important;
  right: 15px !important;
}

p-panelmenu-icon {
  font-size: smaller;
}

.export-button {
  width: 100%;
  height: 50px;
}
