// @import '../src/app/loc-comps/location-dashboard/unused/loc-photos-widget/loc-photos-widget.component.scss';
@import '../src/app/loc-comps/location-dashboard/unused/loc-dashboard/loc-dashboard.component.scss';

@import '../src/theme/theme.css';
@import '../src/theme/variables';

// core
@import '../src/theme/core/base';
@import '../src/theme/core/helpers';
@import '../src/theme/core/pi-overrides';
@import '../src/theme/core/elements';
@import '../src/theme/core/elements/index';
@import '../src/theme/core/form';

// primeng
//@import '../node_modules/primeicons/primeicons.css';
//@import '../node_modules/primeng/resources/primeng.min.css';
