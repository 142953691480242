@import 'variables';

.rs-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // box-shadow: -4px 4px 10px 0 rgba(196, 196, 196, 0.2) !important;
  box-shadow: 0px 0px 10px 0 rgb(196 196 196 / 80%) !important;
  border-top: 1px solid #f6f6f6;
  border-right: 1px solid #f6f6f6;
  height: 310px;
  width: calc(100% - 16px);
  margin-top: 25px;
  border-radius: 6px;
  &.half {
    width: calc(50% - 8px);
    margin: 8px 0;
  }

  .header {
    @include flex-between;
    height: 48px;
    padding: 16px;
    border-bottom: 1px solid #dcdbdb;
    h3 {
      font: 18px;
      text-transform: uppercase;
    }
    .header-action-btn {
      color: $darkGray !important;
    }
  }

  .rs-card-list {
    //
  }

  .rs-card-numbers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 37%;
    text-align: center;
    h2 {
      font-size: 50px;
      margin: 0 0 -10px 0;
      &.green {
        color: rgb(var(--green-color));
      }
    }
    @media only screen and (max-width: 1900px) {
      h2 {
        font-size: 45px;
        margin: 0 0 -10px 0;
      }
    }
    @media only screen and (max-width: 1700px) {
      h2 {
        font-size: 40px;
        margin: 0 0 -10px 0;
      }
    }
    @media only screen and (max-width: 1400px) {
      h2 {
        font-size: 35px;
        margin: 0 0 -10px 0;
      }
    }
    @media only screen and (max-width: 1200px) {
      h2 {
        font-size: 30px;
        margin: 0 0 -10px 0;
      }
    }

    p {
      font-size: 14px;
      margin-top: 10px;
      @media only screen and (max-width: 1200px) {
        font-size: 18px;
      }
    }

    .stars {
      display: inline-flex;

      i {
        display: flex;
        align-items: center;
        font-size: 28px;
        color: $primaryYellow;
        margin-left: 5px;
      }
    }
  }
}
