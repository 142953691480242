@import 'variables', 'core/mixins';

.ttu {
  text-transform: uppercase;
}

.ttc {
  text-transform: capitalize;
}

.ws-nw {
  white-space: nowrap;
}

.tac {
  text-align: center;
}

.fz-18 {
  font-size: 18px;
}
.p-0 {
  padding: 0;
}
.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}
.m-b-0 {
  margin-bottom: 0;
}
.m-t-0 {
  margin-top: 0;
}
.m-t-10 {
  margin-top: 10px;
}
.m-0 {
  margin: 0;
}
.m-l-20 {
  margin-left: 20px;
}
.m-l-0 {
  margin-left: 0px;
}

.flex-center {
  @include flex-center;
}

.flex-between {
  @include flex-between;
}

.flex-between-start {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.no-wrap {
  flex-wrap: nowrap;
}

.flex-row {
  @include flex-between;
}
.flex-1 {
  flex: 1;
}

.flex-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.c-p {
  cursor: pointer;
}

.text-green {
  color: rgb(var(--primary-color));
}

.text-gray {
  color: $darkGray;
}

.text-lightgray {
  color: $lightGray;
}

.text-gray-i {
  color: $darkGray !important;
}

.flex {
  display: flex;
}

.lh-0 {
  line-height: 0;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
